import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ReactMarkdown from "react-markdown"

import "../scss/legal.scss"
import 'uikit/dist/css/uikit.css'

export const query = graphql`
query legalsQuery($id: Int) {
  strapiLegals(strapiId: {eq: $id}) {
    Title
    Content
    slug
    strapiId
  }

}
`



const Legal = ({data, pageContext}) => {



    return(
    <div>
      <Layout type="privacy">
        <SEO title={data.strapiLegals.Title} />

        <div className="title"><h1>{data.strapiLegals.Title}</h1></div>

        <section className="legal-section">
        
        
            <ReactMarkdown children={data.strapiLegals.Content}></ReactMarkdown>
          

         
          
        </section>


      </Layout>
  
  
      
    </div>
  )
    }
  
  export default Legal